import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f9fa',
    color: '#343a40',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    padding: '20px',
  };

  const headingStyle = {
    fontSize: '3rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    color: 'orange',
  };

  const paragraphStyle = {
    fontSize: '1.25rem',
    marginBottom: '1.5rem',
  };

  const linkStyle = {
    fontSize: '1rem',
    color: '#007bff',
    textDecoration: 'none',
    border: '1px solid #007bff',
    padding: '10px 20px',
    borderRadius: '5px',
    transition: 'all 0.3s ease',
  };

  const linkHoverStyle = {
    ...linkStyle,
    backgroundColor: '#007bff',
    color: '#fff',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>404 - Page Not Found</h1>
      <p style={paragraphStyle}>
        Sorry, the page you're looking for doesn't exist or has been moved.
      </p>
      <Link
        to="/admin/dashboard"
        style={linkStyle}
        onMouseEnter={(e) => (e.target.style.backgroundColor = 'orange')}
        onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')}
      >
        Go Back Home
      </Link>
    </div>
  );
};

export default NotFound;
