import React,{useState,useEffect} from "react";
import {
  AreaChart,Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { useUserAuth } from "../../Context/UserAuthContext";
export default function App({width,height}) {
    const { getChartData } = useUserAuth();
    const [metaData,setMetaData]=useState([])
    const [loading, setLoading] = useState(true);
    const loadGraphData=async()=>{
        let RealData = await getChartData('/getContentViewsAnalytics');
        console.log(RealData)
        setMetaData(RealData.data.data)
        console.log(RealData.data.data)
        setLoading(false)
    }
    useEffect(()=>{
        loadGraphData()
    },[])
  return (
    <>
    {loading ? 
    <div className="skeleton" style={{width:"550px"}}></div>:
    <AreaChart
      width={width}
      height={height}
      data={metaData}
      margin={{
        top: 10,
        right: 10,
        left: 10,
        bottom: 10,
      }}
    >
       <defs>
        <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#34A853" stopOpacity={0.8} />
            <stop offset="100%" stopColor="#B8E986" stopOpacity={0.2} />
          </linearGradient>
        </defs>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="_id" tickFormatter={(id) => id.substring(0, 7)} />
      <YAxis/>
      <Tooltip/>
      <Area type="monotone" dataKey="views" stroke="#8884d8" fill="url(#greenGradient)" />
    </AreaChart> }
    </>
     
  );
}
