//mport "./styles.css";
import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
export default function Income({width,height,graphData,type}) {
    const [income,setIncome]=useState({todayIncome:[]})
    const [dataKey,setDataKey]=useState("amount")
    const [todayIncome,setTodayIncome]=useState([])
    useEffect(()=>{
        if(type === "user"){
          setDataKey("count")
        }else if(type === "income"){
          setDataKey("amount")
        }else if(type === "download"){
          setDataKey("count")
        }else if(type === "subscription"){
          setDataKey("count")
        }
    },[])
    const formatYAxis = (tickItem) => {
      if (tickItem >= 1000000) {
        return `${(tickItem / 1000000).toFixed(0)}M`;
      } else if (tickItem >= 1000) {
        return `${(tickItem / 1000).toFixed(0)}K`;
      }
      return tickItem;
    };
    const formatTooltip = (value, name) => {
      switch(type){
        case 'income':
          return name === 'amount' ? [`${value} ₹`, name] : [value, name];
        case 'user':
          return name === 'count' ? [`${value} users`, name] : [value, name];
        case 'download':
          return name === 'count' ? [`${value} downloads`, name] : [value, name];
        case 'subscription':
          return name === 'count' ? [`${value} subscriptions`, name] : [value, name];
        default :
          return 
      }
      // Check if the name is 'amount' and append '₹' symbol to the value
      
    };
  return (
    <AreaChart
      className="grcustom"
      width={width}
      height={height}
      data={graphData}
      margin={{
        top: 10,
        right: 10,
        left: 10,
        bottom: 10,
      }}
    >
       <defs>
    <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
    <stop offset="0%" stopColor="#4285F4" stopOpacity={0.8} />
    <stop offset="100%" stopColor="#A0C1F8" stopOpacity={0.2} />
    </linearGradient>
  </defs>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="_id" />
      <YAxis tickFormatter={formatYAxis}/>
      <Tooltip formatter={formatTooltip} />
      <Area type="monotone" dataKey={dataKey} stroke="#8884d8" fill="url(#colorGradient)" />
    </AreaChart>
  );
}
