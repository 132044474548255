import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { DeepListTypes } from '../../Objects/Country';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../Context/UserAuthContext';
import AsyncSelect from 'react-select/async';
const CreateDeepLink = () => {
  const navigate = useNavigate();
  const {listContents,getSubscriptionPackages,getUpcomingContents}=useUserAuth();
  const [meta,setMeta]=useState({type:{},deepLink:"",contents:[],flag:false,subscriptions:[],upcomings:[]})
  const getAllContents=async()=>{
    let Contents = await listContents();
    console.log(Contents)
    //setMeta({...meta,contents:Contents.data.data})
    return Contents.data.data
  }
  const getAllSubscriptions=async()=>{
    let res = await getSubscriptionPackages();
    //setMeta({...meta,contents:Contents.data.data})
    return res.data.data;
  }
  const getAllUpcomings=async()=>{
    let res = await getUpcomingContents();
    //setMeta({...meta,contents:Contents.data.data})
    return res.data.data;
  }
  useEffect(()=>{
//getAllContents();
  },[])
  
  const handleFieldChange = (e) => {
    setMeta({
      ...meta,
      type: e.value,
      subscriptions: [],
      upcomings: [],
      contents: [],
    });
  };
  
  const CreateDeepLink=()=>{
    console.log(meta)
    const prefix = "http://oyehoye.xyz"
    if(meta.type === 'Content'){
      //https://www.jalva.app/contents/Muzaffarnagar%20-%20The%20Burning%20Love
      setMeta({...meta,deepLink:`${prefix}/contents/${meta.contents[0].title}`})
    }else if(meta.type === 'subscription'){
      setMeta({...meta,deepLink:`${prefix}/subscription`})
    }else{
      setMeta({...meta,deepLink:`${prefix}/upcomings/${meta.upcomings[0].title}`})
    }
  }
  const ReturnHTML = () => {
    switch (meta.type) {
      case "subscription":
        return (
          <div key="subscription-select">
            <label>Select Subscription</label>
            <AsyncSelect
              defaultOptions
              value={meta.subscriptions}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e._id}
              loadOptions={getAllSubscriptions}
              onChange={(e) =>
                setMeta((prev) => ({ ...prev, subscriptions: [e] }))
              }
              className="select form-control p-0"
            />
          </div>
        );
      case "Upcoming":
        return (
          <div key="upcoming-select">
            <label>Select Upcoming</label>
            <AsyncSelect
              defaultOptions
              value={meta.upcomings}
              getOptionLabel={(e) => e.title}
              getOptionValue={(e) => e._id}
              loadOptions={getAllUpcomings}
              onChange={(e) =>
                setMeta((prev) => ({ ...prev, upcomings: [e] }))
              }
              className="select form-control p-0"
            />
          </div>
        );
      case "Content":
        return (
          <div key="content-select">
            <label>Select Content</label>
            <AsyncSelect
              cacheOptions
              defaultOptions
              value={meta.contents}
              getOptionLabel={(e) => e.title}
              getOptionValue={(e) => e._id}
              loadOptions={getAllContents}
              onChange={(e) =>
                setMeta((prev) => ({ ...prev, contents: [e] }))
              }
              className="select form-control p-0"
            />
          </div>
        );
      default:
        return null;
    }
  };
  
  
  return (
    <div className='newBuild'>
      <h1>Create Deeplink</h1>
      <label>Select Type</label>
      <Select className="select form-control p-0"  options={DeepListTypes} onChange={(e)=>handleFieldChange(e)}></Select>
      
      {/* <Select className="select form-control p-0"  options={DeepListTypes} onChange={(e)=>handleFieldChange(e)}></Select> */}
      {ReturnHTML()}
      {
        meta.deepLink && 
        <>
        <label>Deeplink</label>
        <textarea value={meta.deepLink} className='deeplinkTag' />
        </>
      }
     
      <button className='btnSubmit' onClick={CreateDeepLink}>Create</button>
      <button className='btnSubmit btn-danger' onClick={()=>navigate(-1)}>Back</button>
    </div>
  )
}

export default CreateDeepLink