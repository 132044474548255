import React from 'react'

const RefundPolicy = (period) => {
  return `<h3>Refund Policy</h3>
                <p>
                  If we receive a chargeback or payment dispute from a credit
                  card company or bank, your service and/or subscription will be
                  suspended without notice. Applicable chargeback fees will be
                  issued to recovers fees passed on to us by the credit company,
                  plus any outstanding balances accured as a result of the
                  chargeback(s) must be paid in full before service is restored.
                  Instead of issuing a chargeback, contact us to address any
                  billing issues. Requesting a chargeback or opening any sort of
                  dispute for a valid charge from us is fraud, and is never an
                  appropriate or legal means of obtaining a refund.
                </p>

                <p  className="text-br/and mt-4"
                  style={{
                    color: "#ef367e",
                    padding: "1rem 0px",
                    textAlign: "center",
                    fontSize: "21px",
                  }}>
                  Customers can apply for refund in scenarios like double payment, such payments will be refunded within one to 3 days
                  and will credited customer banks bank account within ${period} working days.
                </p>

                <h3
                  className="text-br/and mt-4"
                  style={{
                    color: "red",
                    padding: "3rem 0px",
                    textAlign: "center",
                    fontSize: "23px",
                    textTransform: "uppercase",
                  }}
                >
                  Please Read and make sure you fully understand our refund
                  policy prior to makeing a payment
                </h3>`
}

export default RefundPolicy