// src/components/MoviesList.js
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMovies } from '../Redux/moviesSlice';

const MoviesList = () => {
  // const dispatch = useDispatch();
  // const movies = useSelector((state) => state.movies.movies);
  // const status = useSelector((state) => state.movies.status);
  // const error = useSelector((state) => state.movies.error);

  // useEffect(() => {
  //   if (status === 'idle') {
  //     dispatch(fetchMovies()); 
  //    // Fetch movies only if not already fetched
  //   }
  //   console.log(movies,status,error)
  // }, [dispatch, status]);

  // if (status === 'loading') return <p>Loading...</p>;
  // if (status === 'failed') return <p>Error: {error}</p>;

  return (
    <div>
      <h1>Movies</h1>
     
    </div>
  );
};

export default MoviesList;
