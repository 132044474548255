// src/features/moviesSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Instance from '../Objects/Axios';
// Async thunk to fetch movies
export const fetchMovies = createAsyncThunk('movies/fetchMovies', async () => {
  const response = await Instance.get(`/listContents`) 
  if (response.data.error) {
    throw new Error('Failed to fetch movies');
  }
  const data = await response.data.data;
  return data; // The resolved value becomes the payload
});

export const fetchIncomeData = createAsyncThunk('movies/fetchIncomeData', async (url) => {
  const response = await Instance.get(url)
  if (response.data.error) {
    throw new Error('Failed to fetch income data');
  }
  const data = await response.data.data;
  return data; // The resolved value becomes the payload
});

export const fetchUserData = createAsyncThunk('movies/fetchUserData', async (url) => {
  const response = await Instance.get(url)
  console.log(response)
  if (response.data.error) {
    throw new Error('Failed to fetch user data');
  }
  const data = await response.data.data;
  return data; // The resolved value becomes the payload
});

const moviesSlice = createSlice({
  name: 'movies',
  initialState: {
    movies: [],
    incomeData:{},
    userData:{}, // Store movies globally
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {}, // No sync reducers for now
  extraReducers: (builder) => {
    builder
      .addCase(fetchMovies.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMovies.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.movies = action.payload; // Set the movies in state
      })
      .addCase(fetchMovies.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
    //income store 
    builder
      .addCase(fetchIncomeData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchIncomeData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.incomeData = action.payload; // Set the movies in state
      })
      .addCase(fetchIncomeData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
    
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userData = action.payload; // Set the movies in state
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default moviesSlice.reducer;
