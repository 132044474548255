const GrievancesRedressal = (companyName,name,contactEmail) => {
  return `
  <h3>Grievance Redressal :</h3>
          
  <p>If you have any grievance with respect to any of the Content, Title, Age rating, Synopsis, Parental 
     control feature etc. available on our ${companyName} App, you can register a complaint with our Grievance 
     Redressal Officer on below mentioned details:</p>
          
  <p>Grievance Redressal Officer: ${name} <br/>
     Email id: ${contactEmail}</p>
          
  <p>One can raise a complaint by contacting our Grievance Redressal officer by emailing at ${contactEmail} 
     Upon receipt of the complaint the same shall be acknowledged within a period of 24 hours. Our Grievance Redressal
     team headed by our Grievance Redressal Officer will resolve the complaint within 15 days from the date of receipt 
     of complaint.</p>
`
}
export default GrievancesRedressal