import React from 'react'
import CreateDeepLink from './CreateDeepLink'

const ListMarketing = () => {
  return (
    <>
    <div className="content poster subcr">
        <div className='path'>
          <span className="head">
                  <span className="dashhead m-3">
                    <h3>Home | Marketing</h3>
                  </span>
          </span>
        </div>
        <div className='newBuild'>
          {/* <CreateDeepLink/> */}
          Your Campains
        </div>
    </div>
    </>
  )
}

export default ListMarketing