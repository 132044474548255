import React,{useState,useEffect,useRef} from 'react'
import { useParams,useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../Context/UserAuthContext';
import Select from 'react-select';
import { countries,Listdevice,Listplatform,Listtags,listCategory,listSubCategory, Listgenre,censorCertificates,ageGroups} from '../../../Objects/Country';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AsyncSelect from 'react-select/async';
import { Toaster } from 'react-hot-toast';
import toaster from '../../../Objects/toaster';
const Addnewcontent = () => {
    let {templateName,tab,contentId,contentName,type} = useParams();
    let navigate = useNavigate();
    let formData = new FormData();
    const checkboxFields = [
        "published", "active", "haveSeason", "trailerAvailable", 
        "canShowInTV", "freelyAvailable", "canWatchWithoutLogin", 
        "authenticationNeeded","censor","isTrailer","videoAvailable"
        ,"offlineStreaming"
    ];
    const keysToExclude = [ "_id", "seasons", "__v", "contentId", 
        "categoryCode", "categoryId", "numClicked", 
        "numComments", "ratings", "awsStaticResourcePath", 
        "rank", "statitics", "comments","templateName","censorCertificate","uploadCensorCertificate"];
    const keysToExcludeParam = [
        "_id", "seasons", "__v", 
        "categoryCode", "categoryId", "numClicked", 
        "numComments", "ratings", "awsStaticResourcePath", "statitics", "comments","webLandscapePosterNormal", "webPosterPortraitNormal", "webPortraitPosterNormal",
        "portraitPosterSmall", "portraitPosterNormal", "landscapePosterSmall",  
        "landscapePosterNormal", "webPosterLandscapeNormal", "portraitPosterIdNormal",
        "portraitPosterIdSmall","landscapePosterIdNormal","landscapePosterIdSmall","templateName","uploadCensorCertificate","category"
    ]
    const keysToExcludeParamForAddContent = [
        "_id", "seasons", "__v", 
        "numComments", "ratings", "awsStaticResourcePath", "statitics", "comments","webLandscapePosterNormal", "webPosterPortraitNormal", "webPortraitPosterNormal",
        "portraitPosterSmall", "portraitPosterNormal", "landscapePosterSmall",  
        "landscapePosterNormal", "webPosterLandscapeNormal", "portraitPosterIdNormal",
        "portraitPosterIdSmall","landscapePosterIdNormal","landscapePosterIdSmall","templateName","uploadCensorCertificate","category"
    ]
    const fileInputFields = [
        "webLandscapePosterNormal", "webPosterPortraitNormal", "webPortraitPosterNormal",
        "portraitPosterSmall", "portraitPosterNormal", "landscapePosterSmall",
        "landscapePosterNormal", "webPosterLandscapeNormal", "portraitPosterIdNormal",
        "portraitPosterIdSmall","landscapePosterIdNormal","landscapePosterIdSmall"
    ];
    const [ImpFields,setImpFields]= useState([])
    // let newTemps = [
    //     {id: 'title', text: 'Title (Display title of your content)',type:String},
    //     {id: 'cast', text: 'Cast (Actor and Actress , Can add one or multiple separated by commas)',type:Array},
    //     {id: 'censor', text: 'censor',type:Boolean}
    //     ]
    const {listTemplates,listContents,addContents,updateContents} = useUserAuth();
    const [metaData,setMetaData]=useState({})
    const [param,setParam]=useState({})
    const [text,setText]=useState("<p><strong><em><u>TEST</u></em></strong></p>")
    const [fields,setFields]=useState([])
    const [category,setCategory]=useState([])
    const [countryFiltered,setCountryFiltered]=useState([])
    const [subcategory,setSubcategory]=useState([])
    const [ageGroup,setAgeGroup]=useState([])
    const [censorCertificatesValues,setcensorCertificates]=useState([])
    const [genre,setGenre]=useState([])
    const [tag,setTag]=useState([])
    const [platform,setplatform]=useState([])
    const [newplatform,setNewplatform]=useState([])
    const fileInputRef = useRef(null);
    const actorImageRef = useRef(null);
    const SpecialVars = ['actors','director','producer']
    useEffect(()=>{
        const fetchData=async(type)=>{
            let res,data = "";
            if(type === "add"){
                res = await listTemplates();
                data = res.data.data.filter(x=>x.title === templateName)
                if(data && data.length > 0){
                    res = await listContents();
                    let defLabel = data[0].data; 
                    let newImpFields = [...ImpFields]; 
                    const updatedParam  = defLabel.reduce((acc, temp) => {
                        if (temp.mandatory && !ImpFields.includes(temp.id)) {
                            newImpFields.push(temp.id); // Adds temp.id to the array
                        }
                        if (temp.type === "String") {
                          acc[temp.id] = ''; // Default value for String
                        } else if (temp.type === "Array") {
                          if(SpecialVars.includes(temp.id)){
                            console.log("found")
                            acc[temp.id] = '';
                          }else{
                            acc[temp.id] = []; // Default value for Array
                          }
                        } else if (temp.type === "Boolean") {
                          acc[temp.id] = false; // Default value for Boolean
                        }
                        return acc
                     }, {});
                     console.log(updatedParam)
                     setImpFields(newImpFields);
                    //experiment        
                    
                    // const updatedParam = checkboxFields.reduce((acc, field) => {
                    //     acc[field] = false; // Set default value to false
                    //     return acc;
                    //   }, {});
                    setFields(defLabel)   
                    // setParam({title:'',description:'',production:'',director:'',actors:[],freeTrailer:[],paidTrailer:[],
                    //    portraitPosterIdSmall:'',portraitPosterIdNormal:'',landscapePosterIdSmall:'',landscapePosterIdNormal:'',
                    //    categoryName:'',rank:res.data.data.length + 1
                    // })
                    // let result = Object.fromEntries(
                    //     Object.entries(updatedParam).filter(([key]) => !keysToExcludeParam.includes(key))
                    // );
                    //.log(ImpFields)
                    console.log(updatedParam)
                    setParam((prevParam) =>({ ...prevParam,...updatedParam }))
                }
            }else{
                res = await listContents();
                data = res.data.data.filter(x=>x._id === contentId)
                const cu =data[0];
                setParam(cu)
                setMetaData(cu)
                transCountry(cu);
                let result = Object.entries(cu).map(([key,value])=>({
                    id:key,text:key,value:value
                }));
                //sorting based on aplhabatical Order
                result.sort((a,b)=> a.id.localeCompare(b.id))
                setFields(result)
            }
          }
        fetchData(type)
    },[])
    const transCountry=async(data)=>{
        const ss = countries;
        let allCategoryList = await listCategory();
        let allSubcategoryList = await listSubCategory();
        let country = data.countryFiltered;
        let categoryName = data.categoryName;

        const countrySet = new Set(country);
        const subSet  = new Set(data.subcategory);

        const arr = ss.filter(item => countrySet.has(item.value));
        const catArr = allCategoryList.filter(item => categoryName === item.title);
        const subArr = allSubcategoryList.filter(item => subSet.has(item.title) )

        setSubcategory(subArr)
        setCategory(catArr)

        const updatedPlatform = data.deviceFiltered.map(x => ({ label: x, value: x }));
        const updatedGenre = data.genre.map(x => ({ label: x, value: x }));
        const updatedTags = data.tags.map(x => ({ label: x, value: x }));

        setplatform(updatedPlatform);
        setTag(updatedTags)
        setGenre(updatedGenre)
        setCountryFiltered(arr)
    }
    const [preview, setPreview] = useState(null);
    const checkCertificateSize = (file,width,height) => {
        return new Promise((resolve,reject)=>{
            const validTypes = ['image/png', 'image/jpeg'];
            if (!validTypes.includes(file.type)) {
                toaster('Unsupported file type. Only PNG, JPEG, and JPG are allowed.','error');
                setPreview(null);
                fileInputRef.current.value = ''; // Clear the file input
                return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                  if (img.width <= width || img.height <= height) {
                   // setPreview(e.target.result);
                    resolve(true)
                  } else {
                    toaster("Image dimensions should not exceed 1200 x 800 pixels.","error")
                   // setPreview(null);
                   // fileInputRef.current.value = ''; 
                    resolve(false)
                  }
                };
        
                img.onerror = () => {
                   // setPreview(null);
                   // fileInputRef.current.value = ''; 
                    resolve(false)
                };
              };
              reader.onerror = () => {
                reject(new Error('Failed to read the file.'));
              };
              reader.readAsDataURL(file);

        })
    };

    const formatContents=(value)=>{
        let sepVal = ""
        console.log(value)
        if(Array.isArray(value)){
           sepVal = value;
        }else if(value === ''){
            sepVal = [];
        }else{
           sepVal = value.split(",");
        }
        return sepVal.map(text=> text.replace(/\b\w/g, (char) => char.toUpperCase()).replace(/\B\w+/g, (char) => char.toLowerCase()))
    }
    const isObjectFull = (obj) => {
        return Object.values(obj).every(value => value !== "" && value !== null && value !== undefined && value.length !== 0);
    };
    const addFieldToFormData = (fieldName, paramValue, metaDataValue) => {
        if ((type === 'add' && paramValue !== undefined && paramValue !== '') ||  (type === 'update' && paramValue !== metaDataValue)) {
          formData.append(fieldName, paramValue);
        }
    };
    const handleSubmit=async()=>{
        let result = {};
        let cusflag =false;
        result = Object.fromEntries(
            Object.entries(param).filter(([key]) => !keysToExcludeParam.includes(key))
        );
        if(type === 'update'){
            cusflag =  isObjectFull(result)
        }else{
            if(castCrew.length > 0){
                // const removingActorImages = {
                //     ...result,
                //     otherInfo: param.otherCastCrew.map(({ name }) => ({ name })), // Remove `image` property
                // };
               // console.log(removingActorImages)
                param["otherCastCrew"] = castCrew.map(({ name }) => ({ name }));
                castCrew.forEach((item, index) => {
                    if (item.image) {
                      formData.append(`actor[${index}][image]`, item.image);
                    }
                  });
            }
            let {category,...rest}= param;
            addFieldToFormData('portraitSmall', rest.portraitPosterSmall, metaData.portraitPosterSmall);
            addFieldToFormData('portraitNormal', rest.portraitPosterNormal, metaData.portraitPosterNormal);
            addFieldToFormData('landscapeSmall', rest.landscapePosterSmall, metaData.landscapePosterSmall);
            addFieldToFormData('landscapeNormal', rest.landscapePosterNormal, metaData.landscapePosterNormal);
            if(rest.uploadCensorCertificate){
                addFieldToFormData('uploadCensorCertificate', rest.uploadCensorCertificate, metaData.uploadCensorCertificate);  
            }
            let filteredObj = Object.fromEntries(
                Object.entries(rest).filter(([key]) => ImpFields.includes(key))
            );
            //checking imp fields filled or not 
            cusflag = isObjectFull(filteredObj);
            result = Object.fromEntries(
                Object.entries(rest).filter(([key]) => !keysToExcludeParamForAddContent.includes(key))
            );
            if(cusflag){
             transformDataForAPI(result);
            }else{
             toaster("Please Fill All The Mandatory Fields !","error")  
            }
        }
        
        if(cusflag === 'test'){
            result["title"] = param.title.trim();
            let textOnly = param.description.replace(/<[^>]+>/g, ''); // Remove HTML tags
            result["description"] = textOnly
            if(param.actors){
                result["actors"] = formatContents(param.actors);
            }
            if(param.producer){
                result["producer"] = formatContents(param.producer);
            }
            result["countryFiltered"]=countryFiltered.map(x=>x.value);
            result["deviceFiltered"]=platform.map(x=>x.value);
            result["streamFiltered"]= newplatform.map(x=>x.value);
            result["ageGroup"]= ageGroup.map(x=>x.value);
            if(param.censor){
                result["censorCertificate"]= censorCertificatesValues.map(x=>x.value);
                addFieldToFormData('uploadCensorCertificate', param.uploadCensorCertificate, metaData.uploadCensorCertificate);
            }
            
            result["tags"]=tag.map(x=>x.value);
            result["genre"]=genre.map(x=>x.value);
            result["subcategory"]=subcategory.map(x=>x.title);
            if(param.trailerFileUrl){
                result["trailerFileUrl"] = Array.isArray(param.trailerFileUrl) ? param.trailerFileUrl:[param.trailerFileUrl];
            }           
            result["paidTrailer"] = Array.isArray(param.paidTrailer) ? param.paidTrailer:[param.paidTrailer];
            result["freeTrailer"] = Array.isArray(param.freeTrailer) ? param.freeTrailer:[param.freeTrailer];
            if(param.genericTrailer){
                result["genericTrailer"] = Array.isArray(param.genericTrailer) ? param.genericTrailer:[param.genericTrailer];
            }
            addFieldToFormData('portraitSmall', param.portraitPosterIdSmall, metaData.portraitPosterIdSmall);
            addFieldToFormData('portraitNormal', param.portraitPosterIdNormal, metaData.portraitPosterIdNormal);
            addFieldToFormData('landscapeSmall', param.landscapePosterIdSmall, metaData.landscapePosterIdSmall);
            addFieldToFormData('landscapeNormal', param.landscapePosterIdNormal, metaData.landscapePosterIdNormal);
            addFieldToFormData('webPosterPortraitNormal', param.webPosterPortraitNormal, metaData.webPosterPortraitNormal);
            addFieldToFormData('webPosterLandscapeNormal', param.webPosterLandscapeNormal, metaData.webPosterLandscapeNormal);
           
            let categoryObj = {};
            if(Array.isArray(category)){
                categoryObj= category[0];
            }else{
                categoryObj = category;
            }
            result["categoryName"]=categoryObj.title;
            result["categoryId"]=categoryObj._id;
            result["categoryCode"]=categoryObj.code;
            const formText =JSON.stringify(result);
            formData.append('formText', formText);
            const body = formData;
            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            let res={};
            if(type !== "add"){
                res = await updateContents(body);
                handleBack(res)
            }else{
             //   console.log(body)
                // res = await addContents(body);
                // console.log(res)
                // handleBack(res)
            }
        }else{
          // toaster("Please Fill All The Mandatory Fields ...","error")  
        }
    }
    const [castCrew,setCastCrew]=useState([])
    const [actorobj,setActorObj]=useState({name:'',image:''})
    const handleCastAndCrew=async(actorobj)=>{
        if (actorobj.name && actorobj.image) {
            let re = await checkCertificateSize(actorobj.image,200,300)
            if(re){
                setCastCrew((prevItems) => [...prevItems, actorobj]); // Add new item to the array
                setActorObj({ name: '', image: '' });
                actorImageRef.current.value = ''
            }else{
                actorImageRef.current.value = ''
                toaster("Please upload actors image in 200 x 300 px","error")
            }
             // Reset form inputs
        } else {
            toaster("Both name and image are required!","error")
        }
    }
    const transformDataForAPI=async(result)=>{
    // Trimming title
    result["title"] = result.title.trim();
    result["description"] = result.description;
    // Keys to process with formatContent
    console.log(result)
    const keysToFormat = ["actors", "producer","director"];
    keysToFormat.forEach((key) => {
        console.log("debugging",result[key])
        if (Object.hasOwn(result,key)) {
            console.log("formatting")
            result[key] = formatContents(result[key]);
        }
    });
    // Keys to map with specific arrays
    const mappings = {
        countryFiltered: countryFiltered,
        deviceFiltered: platform,
        streamFiltered: newplatform,
        ageGroup: ageGroup,
        subcategory:subcategory,
        genre:genre,
        tags:tag,
        censorCertificate:censorCertificatesValues
    };
    Object.entries(mappings).forEach(([key, sourceArray]) => {
        if (result[key] &&  Array.isArray(sourceArray)) {
            if(result[key] && key === 'subcategory'){
                result[key] = sourceArray.map((x) => x.title)
            }else{
                result[key] = sourceArray.map((x) => x.value);
            }
        }
    });

    const formText =JSON.stringify(result);
    formData.append('formText', formText);
    const body = formData;
    for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
    }
    let res = await addContents(body);
       console.log(res)
    handleBack(res)
    }
    const handleBack=(res)=>{
        if(!res.data.error){
            toaster(res.data.message,"success")
            setTimeout(()=>{
                navigate(-1)
            },[1500])
        }else{
            toaster(res.data.message,"error")
        }
    }
    const handleFieldChange=async(e,x)=>{
        switch(x.id){
            case 'title':
                return setParam({...param, title:e.target.value })
            case 'cast':
               return setParam({...param, actors:e.target.value })
            case 'actors': 
                return setParam({...param, actors:e.target.value })   
            case 'releaseDate':
                return setParam({...param, releaseDate:e.target.value })
            case 'description':              
               return setParam({...param, description:e}) 
            case 'published':
                return setParam({...param, published:e.target.checked })
            case 'active':
                return setParam({...param, active:e.target.checked })
            case 'tags':
                setParam({...param, tags:e })
                return setTag(Array.isArray(e)?e.map(x=>x):[])
            case "countryFiltered":
                setCountryFiltered(Array.isArray(e)?e.map(x=>x):[])
                return setParam({...param, countryFiltered:e})
            case "streamFiltered":
                setNewplatform(Array.isArray(e)?e.map(x=>x):[])
                return setParam({...param, streamFiltered:e})
            case "deviceFiltered":
                setplatform(Array.isArray(e)?e.map(x=>x):[])
                return// setParam({...param, deviceFiltered:Array.isArray(e)?e.map(x=>x.label):[] })
            case 'categoryName':
                setCategory(e)
                return  setParam({...param, categoryName:e.title})
            case 'category':
                setCategory(e)
                return setParam({...param, categoryName:e.title,categoryCode:e.code,categoryId:e._id})
            case 'subcategory':
                setSubcategory(Array.isArray(e)?e.map(x=>x):[])
                return setParam({...param, subcategory:e})
            case 'censor':
                return setParam({...param, censor:e.target.checked})
            case 'censorCertificate':
                console.log(e)
                setcensorCertificates(Array.isArray(e)?e.map(x=>x):[])
                return setParam({...param, censorCertificate:e})
            case 'uploadCensorCertificate':
                let checkStatus = await checkCertificateSize(e.target.files[0],1200,800)
                if(checkStatus){
                    setPreview(URL.createObjectURL(e.target.files[0]));
                    return setParam({...param, uploadCensorCertificate:e.target.files[0]})
                }else{
                    fileInputRef.current.value = ''; 
                }
                return;
            case 'genre':
                setGenre(Array.isArray(e)?e.map(x=>x):[])
                return setParam({...param, genre:e})
            case 'offlineStreaming':
                return setParam({...param, offlineStreaming:e.target.checked })
            case 'ageGroup':
                setAgeGroup(e)
                setParam({...param, ageGroup:e})
                return 
            case 'director':
                // return setParam({...param, director:Array.isArray(sepVal)?sepVal.map(x=> x.trim() ):[] })
                return setParam({...param, director:e.target.value })
            case 'production':
                return setParam({...param, production:e.target.value })
            case 'otherCastCrew':
                return setParam({...param, otherCastCrew:e.target.value})
                //return setParam({...param, otherCastCrew:Array.isArray(e)?e.map(x=>x.value):[]})
            case 'producer':
                return setParam({...param, producer:e.target.value })
            case 'freelyAvailable':
                return setParam({...param, freelyAvailable:e.target.checked })
            case 'canWatchWithoutLogin':
                return setParam({...param, canWatchWithoutLogin:e.target.checked })
            case 'portraitPosterSmall':
                return setParam({...param, portraitPosterSmall:e.target.files[0] })
            case 'portraitPosterIdSmall':
                return setParam({...param, portraitPosterIdSmall:e.target.files[0] })
            case 'portraitPosterNormal':
                return setParam({...param, portraitPosterNormal:e.target.files[0] })
            case 'portraitPosterIdNormal':
                return setParam({...param, portraitPosterIdNormal:e.target.files[0] })
            case 'webPortraitPosterNormal':
                return setParam({...param, webPosterPortraitNormal:e.target.files[0] })
            case 'webPosterPortraitNormal':
                return setParam({...param, webPosterPortraitNormal:e.target.files[0] })
            case 'landscapePosterSmall':
                return setParam({...param, landscapePosterSmall:e.target.files[0] })
            case 'landscapePosterIdSmall':
                return setParam({...param, landscapePosterIdSmall:e.target.files[0] })
            case 'landscapePosterNormal':
                return setParam({...param, landscapePosterNormal:e.target.files[0] })
            case 'landscapePosterIdNormal':
                return setParam({...param, landscapePosterIdNormal:e.target.files[0] })
            case 'webLandscapePosterNormal':
                return setParam({...param, webPosterLandscapeNormal:e.target.files[0] })
            case 'webPosterLandscapeNormal':
                return setParam({...param, webPosterLandscapeNormal:e.target.files[0] })
            case 'genericTrailer':
                return setParam({...param, genericTrailer:e.target.value })
            case 'freeTrailer':
                return setParam({...param, freeTrailer:e.target.value })
            case 'paidTrailer':
                return setParam({...param, paidTrailer:e.target.value })
            case 'trailerAvailable':
                return setParam({...param, trailerAvailable:e.target.checked })
            case 'videoAvailable':
                return setParam({...param, videoAvailable:e.target.checked })    
            case 'isTrailer':
                return setParam({...param, isTrailer:e.target.checked })
            case 'canShowInTV':
                return setParam({...param, canShowInTV:e.target.checked })
            case 'haveSeason':
                return setParam({...param, haveSeason:e.target.checked })  
            case 'authenticationNeeded':
                return setParam({...param, authenticationNeeded:e.target.checked })  
            case 'trailerFileUrl':
                return setParam({...param, trailerFileUrl:e.target.value })  
            default :
                return 
        }
    }
  return (
    <div className='Temp'>
        <div className='advForm'>
        <p>you are adding content using <span>{templateName}</span></p>
        {fields.map((x,index)=>{
           if (checkboxFields.includes(x.id)) {
            return (
              <div key={index}>
                <input 
                  type="checkbox" 
                  checked={param[x.id]} 
                  onChange={(e) => handleFieldChange(e, x)}
                />
                <label>{x.text }{x.mandatory ?<span className="required">*</span>:''} </label>
              </div>
            );
            }else if(x.id === "countryFiltered"){
                return (
                    <div key={index}>
                   <label>{x.text}{x.mandatory ?<span className="required">*</span>:''} </label>
                   <Select className="select form-control p-0" isMulti value={countryFiltered}  options={countries} onChange={(e)=>handleFieldChange(e,x)}></Select>
                    </div>
                )
            }else if(param.censor && x.id === "censorCertificate"){
                return (
                    <div key={index}>
                   <label>{x.text}{x.mandatory ?<span className="required">*</span>:''} </label>
                   <Select className="select form-control p-0" isMulti value={censorCertificatesValues}  options={censorCertificates} onChange={(e)=>handleFieldChange(e,x)}></Select>
                    </div>
                )
            }else if(x.id === "ageGroup"){
                return (
                    <div key={index}>
                   <label>{x.text}{x.mandatory ?<span className="required">*</span>:''} </label>
                   <Select className="select form-control p-0" isMulti value={ageGroup}  options={ageGroups } onChange={(e)=>handleFieldChange(e,x)}></Select>
                    </div>
                )
            }else if(x.id === "deviceFiltered"){
                return (
                    <div key={index}>
                   <label>{x.text}{x.mandatory ?<span className="required">*</span>:''} </label>
                   <Select className="select form-control p-0" isMulti value={platform} options={Listdevice} onChange={(e)=>handleFieldChange(e,x)}></Select>
                    </div>
                )
            }else if(x.id === "streamFiltered"){
                return (
                    <div key={index}>
                   <label>{x.text}{x.mandatory ?<span className="required">*</span>:''} </label>
                   <Select className="select form-control p-0" isMulti value={newplatform} options={Listplatform} onChange={(e)=>handleFieldChange(e,x)}></Select>
                    </div>
                )
            }else if(x.id === "tags"){
                return (
                    <div>
                   <label>{x.text}{x.mandatory ?<span className="required">*</span>:''} </label>
                   <Select className="select form-control p-0" isMulti value={tag} options={Listtags} onChange={(e)=>handleFieldChange(e,x)}></Select>
                    </div>
                )
            }else if(x.id === "genre"){
                return (
                    <div>
                   <label>{x.text} {x.mandatory ?<span className="required">*</span>:''} </label>
                   <Select className="select form-control p-0" isMulti value={genre} options={Listgenre} onChange={(e)=>handleFieldChange(e,x)}></Select>
                    </div>
                )
            }else if(x.id === "category" || x.id === "categoryName"){
                return (
                    <div>
                   <label>{x.text} {x.mandatory ?<span className="required">*</span>:''}  </label>
                   <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={category}
                        defaultValue={category}
                        getOptionLabel={e => e.title}
                        getOptionValue={e => e._id}
                        loadOptions={listCategory}
                        onChange={(e)=>handleFieldChange(e,x)}
                        placeholder={x.text}
                        className="select form-control p-0"
                    />
                  
                    </div>
                )
            }else if(x.id === "subcategory"){
                return (
                    <div>
                   <label>{x.text} {x.mandatory ?<span className="required">*</span>:''} </label>
                   <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions
                        value={subcategory}
                        getOptionLabel={e => e.title}
                        getOptionValue={e => e._id}
                        loadOptions={listSubCategory}
                        onChange={(e)=>handleFieldChange(e,x)}
                        className="select form-control p-0"
                        placeholder={x.text}
                    />
                    </div>
                )
            }else if(fileInputFields.includes(x.id)){
                return (
                    <div>
                   <label>{x.text}{x.mandatory ?<span className="required">*</span>:''} </label>
                   <input
                        className="form-control"
                        type="file"
                        onChange={(e)=>handleFieldChange(e,x)}
                    />
                    </div>
                )
            }else if(param.censor && x.id === "uploadCensorCertificate"){
                return (
                    <div>
                   <label>{x.text}{x.mandatory ?<span className="required">*</span>:''} </label>
                   <input
                        className="form-control"
                        type="file"
                        onChange={(e)=>handleFieldChange(e,x)}
                        ref={fileInputRef}
                    />
                    {preview && <img src={preview} alt="Preview" style={{ maxWidth: '240px', margin: '10px' }} />}
                    </div>
                )
            }else if(x.id === "cast" || x.id === "actors" || x.id === "producer"){
                return (
                    <div>
                    <label>{x.text} {x.mandatory ?<span className="required">*</span>:''} </label><br/>
                    <input type="text" placeholder={x.text} onChange={(e)=>handleFieldChange(e,x)}/>
                    </div>
                )
            }else if(x.id === "releaseDate"){
                return (
                    <div>
                    <label>{x.text} {x.mandatory ?<span className="required">*</span>:''} </label><br/>
                    <input type="date" placeholder={x.text}  onChange={(e)=>handleFieldChange(e,x)}/>
                    </div>
                )
            }else if(x.id === "description"){
                return (
                    <div>
                    <label>{x.text} {x.mandatory ?<span className="required">*</span>:''} </label><br/>
                    <ReactQuill className="reactQuill" onChange={(e)=>handleFieldChange(e,x)} />
                    </div>
                )
            }else if(x.id === 'otherCastCrew'){
                return(
                    <div className="otherCastAndCrew">
                    <h2>Cast And Crew Section</h2>
                    <label>Name</label><br/>
                    <input type="text" value={actorobj.name} placeholder="enter actor name" onChange={(e)=>setActorObj({...actorobj,name:e.target.value})}/>
                    <br/>
                    <label>Select Image (Size 200 x 300 px)</label>
                    <input type="file" ref={actorImageRef} className="form-control" onChange={(e)=>setActorObj({...actorobj,image:e.target.files[0]})}/>
                    
                    <button className='btnSubmit' onClick={()=>handleCastAndCrew(actorobj)}>Add</button>
                    <div className="aPreview">
                    {castCrew.length > 0 && castCrew.map((x,index)=>{
                        return (
                            <div key={index} className="actorContainer">
                            
                            <img src={URL.createObjectURL(x.image)} alt="Preview" style={{ maxWidth: '120px', margin: '10px' }} />
                            <label>{x.name}</label>
                            </div>
                        )
                    })}
                    </div>
                    
                    </div>
                )
            }
            else if(keysToExclude.includes(x.id)){
                return 
            }else{
                return (
                    <div>
                    <label>{x.text} {x.mandatory ?<span className="required">*</span>:''} </label><br/>
                    <input type="text" placeholder={x.text} onChange={(e)=>handleFieldChange(e,x)}/>
                    </div>
                )
            }
           
        })}
        
        <button className='btnSubmit' onClick={handleSubmit}  >Submit</button>
        <button className='btnSubmit btn-danger' onClick={()=>navigate(-1)}>Back</button>
        </div>
        <Toaster/>
    </div>
  )
}

export default Addnewcontent